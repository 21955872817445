import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyGoogleMap from "../../utils/MyGoogleMap";
import MapPolygon from "../../utils/Maps/MapPolygon";
import "./Contact.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Divider from "@mui/material/Divider";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import MainApi from "../../utils/MainApi";

function Contacts({ aboutUs, language, selectedCity }) {
  const { t } = useTranslation();
  const [alignment, setAlignment] = React.useState("All");
  const [chosenRestaraunt, setChosenRestaraunt] = React.useState("");
  const [locations, setLocations] = React.useState([]);
  const [zones, setZones] = React.useState([]);
  const [selectedRestaraunt, setSelectedRestaraunt] = React.useState(false);
  const [typeOfMap, setTypeOfMap] = React.useState("takeAway");

  const buttonStyle = {
    borderRadius: "20px",
    border: "1px solid black",
    fontFamily: "inherit",
    fontSize: "12px",
  };

  const buttonMapStyle = {
    border: "none",
    fontFamily: "inherit",
    fontSize: "12px",
  };

  const getLocations = (arrayRestaurants) => {
    if (!arrayRestaurants) return [];
    return arrayRestaurants.map((restaurant) => ({
      lat: restaurant.coordinates.latitude,
      lng: restaurant.coordinates.longitude,
    }));
  };

  const getAllLocations = () => {
    if (!aboutUs) return {};
    const AllLocations = aboutUs?.flatMap((item) => {
      return item.restaurants?.map((restaurant) => ({
        lat: restaurant.coordinates.latitude,
        lng: restaurant.coordinates.longitude,
      }));
    });
    return AllLocations;
  };

  const getZones = async () => {
    try {
      const res = await MainApi.getCoordinates();
      return res;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const zones = await getZones();
        setZones(zones);
      } catch (error) {
        console.error("Ошибка при получении зон:", error);
      }
    };
    fetchZones();
  }, []);

  // Деструктурируем delivery из aboutUs
  // const { restaurants, delivery } = aboutUs[0];

  const getCityRestaraunt = (city) => {
    const cityRestaraunt = aboutUs.filter((item) => {
      if (item.city === city) return { city: item.city, ...item.restaurants };
    });
    return cityRestaraunt;
  };

  const getAllRestaraunts = () => {
    const AllRest = aboutUs?.flatMap((item) => {
      return item.restaurants?.map((restaurant) => ({
        city: item.city,
        ...restaurant,
      }));
    });
    return AllRest;
  };

  useEffect(() => {
    if (!aboutUs) return;
    const AllLocations = getAllLocations();
    setLocations(AllLocations);
    const allRest = getAllRestaraunts();
    setChosenRestaraunt(allRest);
  }, [aboutUs]);

  const handleAlignment = (event, newAlignment) => {
    const target = event.target.value;
    if (target !== "All") {
      const arrRestaraunts = getCityRestaraunt(target)[0].restaurants.map(
        (restaurant) => {
          return {
            ...restaurant,
            city: target,
          };
        }
      );

      setChosenRestaraunt(arrRestaraunts);
      const loc = getLocations(arrRestaraunts);
      setLocations(loc);
    } else {
      const allRest = getAllRestaraunts();
      setChosenRestaraunt(allRest);
      const loc = getAllLocations();
      setLocations(loc);
    }
    setAlignment(target);
    setSelectedRestaraunt(false);
  };

  const handleRestaraunt = (value) => {
    const restaurantsCity = aboutUs.filter((item) => {
      return item.city === value.city;
    });
    const restaurant = restaurantsCity[0].restaurants.filter((item) => {
      return item.id === value.id;
    });
    restaurant[0].city = value.city;
    const loc = getLocations(restaurant);
    setLocations(loc);
    setSelectedRestaraunt(value.id);
  };

  const changeTypeOfMap = (e) => {
    const typeOfMap = e.target.value;
    setTypeOfMap(typeOfMap);
  };

  useEffect(() => {
    if (selectedCity.city) {
      setAlignment(selectedCity.city);
      const event = { target: { value: selectedCity.city } };
      handleAlignment(event);
    }
  }, [selectedCity]);

  return (
    <>
      <div className="contacts">
        <h2 className="contacts__title">{t("contacts.title", "О нас")}</h2>

        <div>
          <ToggleButtonGroup
            value={typeOfMap}
            exclusive
            onChange={(e) => changeTypeOfMap(e)}
            aria-label="centered"
            sx={{ fontFamily: "inherit" }}
            className="typeMap_buttonGroup"
            fullWidth={true}
          >
            <ToggleButton
              sx={buttonMapStyle}
              disableRipple
              value="takeAway"
              aria-label="left aligned"
            >
              {t("map.restaurant", "Рестораны")}
            </ToggleButton>
            <ToggleButton
              sx={buttonMapStyle}
              disableRipple
              value="delivery"
              aria-label="right aligned"
            >
              {t("map.delivery", "Зоны доставки")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="contacts__container">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            sx={{ borderRadius: "20px", fontFamily: "inherit" }}
            className="contacts__buttonGroup"
          >
            {typeOfMap === "takeAway" && (
              <ToggleButton
                sx={buttonStyle}
                disableRipple
                value="All"
                aria-label="left aligned"
              >
                {t("map.all_city", " Все города")}
              </ToggleButton>
            )}

            <ToggleButton
              sx={buttonStyle}
              disableRipple
              value="Beograd"
              aria-label="centered"
            >
              {t("map.Beograd", "Белград")}
            </ToggleButton>
            <ToggleButton
              sx={buttonStyle}
              disableRipple
              value="NoviSad"
              aria-label="right aligned"
            >
              {t("map.NoviSad", "Нови сад")}
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="contact__container">
            {typeOfMap === "takeAway" && (
              <div className="contact__info">
                <div className="contact__infoTitles">
                  <button
                    className={`contact__infoTitle  ${
                      typeOfMap === "takeAway" && "contact__infoTitle--red"
                    } `}
                    onClick={() => setTypeOfMap("takeAway")}
                  >
                    {t("map.restaurant", "Рестораны")}
                  </button>
                </div>

                <Divider sx={{ margin: "0 0 10px 0" }} />
                {typeOfMap === "takeAway" && aboutUs ? (
                  <div className="contact__infoList">
                    {chosenRestaraunt &&
                      chosenRestaraunt.map((restaurant, i) => {
                        return (
                          <div
                            className={`contacts__restarauntBlock ${
                              selectedRestaraunt === restaurant.id &&
                              "contacts__restarauntBlock--select"
                            }`}
                            key={i}
                          >
                            <RamenDiningIcon
                              sx={{
                                margin: "10px 3px 10px 5px",
                                color:
                                  selectedRestaraunt === restaurant.id
                                    ? "#ff3055"
                                    : "#cb9ba4",
                              }}
                            />
                            <div
                              className="contact__restaraunt"
                              onClick={() =>
                                handleRestaraunt({
                                  city: restaurant.city,
                                  id: restaurant.id,
                                })
                              }
                            >
                              <p className="contact__description city">
                                {restaurant.city}
                              </p>
                              <p className="contact__description city">
                                {restaurant.address}
                              </p>
                              <p className="contact__description workTime">
                                {t("contacts.time", "Рабочее время: ")}
                              </p>
                              <p className="contact__description workTime">
                                {restaurant.open_time} - {restaurant.close_time}
                              </p>
                              <p className="contact__description telephone">
                                {t("contacts.phone", "Телефон для заказов: ")}

                                {restaurant.phone}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    <Divider sx={{ margin: "10px 0 10px 0" }} />
                  </div>
                ) : (
                  <div className="contact__infoList">Загружаем...</div>
                )}
              </div>
            )}

            <div className="contacts__map">
              {aboutUs && typeOfMap === "takeAway" ? (
                <MyGoogleMap locations={locations} />
              ) : aboutUs ? (
                <div className="contacts__polygones">
                  {zones && <MapPolygon zones={zones} alignment={alignment} />}
                </div>
              ) : (
                <div className="contacts__mapPreloader"></div>
              )}
            </div>
          </div>
        </div>
        <div className="contacts__delivery">
          {aboutUs?.flatMap(({ delivery }) =>
            delivery?.map((deliveryItem, i) => (
              <div key={i} className="contacts__delivery-item">
                <p
                  className="contacts__delivery-description"
                  dangerouslySetInnerHTML={{
                    __html: deliveryItem.translations[language]?.description,
                  }}
                ></p>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default Contacts;
