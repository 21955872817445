import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  InfoBox,
} from "@react-google-maps/api";
import Loader from "../../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./MapPolygone.css";

//хук для отображение карт
function MapPolygon({ zones, alignment }) {
  const [mapDimensions, setMapDimensions] = useState({
    width: "100%",
    height: "60vh",
    maxHeight: "900px",
    margin: "0 0",
  });
  const { t } = useTranslation();
  const [pathsAll, setPathAll] = useState("");

  const [selectedPolygon, setSelectedPolygon] = useState(null);

  const handlePolygonClick = (item, event, key) => {
    // Здесь вы можете добавить дополнительную информацию в объект item, если это необходимо
    setSelectedPolygon({
      ...item,
      position: event.latLng,
      key: key, // Сохраняем позицию клика для отображения InfoWindow
    });
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     // Когда размер окна изменяется
  //     // можно задать логику для изменения размеров карты в зависимости от размеров окна
  //     setMapDimensions({
  //       width: window.innerWidth < 2000 ? "100%" : "300px",
  //       height: "300px",
  //       margin: "20px 0",
  //     });
  //   };

  //   // Добавляем обработчик события при монтировании компонента
  //   window.addEventListener("resize", handleResize);

  //   // Вызываем обработчик немедленно, чтобы установить начальное состояние
  //   handleResize();

  //   // Удалеем обработчик события при размонтировании компонента
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // Объявляем переменную bounds внутри компонента
  let bounds;

  // Проверяем, что API карт Google загружен
  // if (window.google && window.google.maps) {
  // }

  //   const onLoad = (map) => {
  //     const bounds = new window.google.maps.LatLngBounds();

  //   };

  function deleteMulti(coordinates) {
    const regex = /\(|\)/g;
    const newSource = coordinates
      .replace(regex, "")
      .split(", ")
      .map((coord) => {
        const [lng, lat] = coord.split(" ");
        return { lng: parseFloat(lng), lat: parseFloat(lat) };
      });

    return newSource;
  }

  const centers = {
    Beograd: { lat: 44.8090336, lng: 20.4580446 },
    NoviSad: {
      lat: 45.252353,
      lng: 19.800517,
    },
    All: { lat: 44.8090336, lng: 20.4580446 },
  };

  const paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 },
  ];

  useEffect(() => {
    const newPath = [];
    if (zones) {
      Object.keys(zones).forEach((key) => {
        Object.keys(zones[key]).forEach((newKey) => {
          // newPath.push(value)

          const value = zones[key][newKey].coordinates;
          if (value) {
            const coordinates = deleteMulti(value);
            const cost = zones[key][newKey].delivery_cost;
            const isPromo = zones[key][newKey].is_promo;
            const promoMinOrder = zones[key][newKey].promo_min_order_amount;
            const newZone = {
              coordinates: coordinates,
              delivery_cost: cost,
              is_promo: isPromo,
              promo_min_order_amount: promoMinOrder,
            };
            newPath.push(newZone);
          }
        });
      });
    }

   console.log(newPath)
    setPathAll(newPath);
  }, []);

  const zonesColors = {
    0: "blue",
    1: "yellow",
    2: "red",
    3: "green",
    4: "brown",
    5: "yellow",
    6: "purple",
    7: "orange",
    8: "pink",
    9: "grey",
  };

  const options = {
    // fillColor: "white",
    fillOpacity: 0.5,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  const onLoad = (polygon) => {};

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      // preventGoogleFontsLoading={false}
      loadingElement={
        <Loader text={t("addresses.loading", "Загружаем карту...")} />
      }
    >
      <GoogleMap
        mapContainerStyle={mapDimensions}
        zoom={13}
        // onLoad={onLoad}
        options={{ streetViewControl: false }}
        center={centers[alignment]}
      >
        {pathsAll &&
          pathsAll.map((item, i) => (
            <React.Fragment key={i}>
              <Polygon
                onLoad={onLoad}
                paths={item.coordinates}
                options={{ ...options, fillColor: zonesColors[i] }}
                onClick={(event) => handlePolygonClick(item, event, i)}
              />
              {selectedPolygon && selectedPolygon.key === i && (
                <InfoBox position={selectedPolygon.position}>
                  <Card sx={{ minWidth: 100 }}>
                    <CardContent>
                      <Typography
                        gutterBottom
                        sx={{ color: "text.secondary", fontSize: 14 }}
                      >
                        {t("map.zona", "Зона")} {item.delivery_cost} RSD
                        {item.is_promo && (
                          <div>
                            {t("map.free_delivery", "Бесплатная доставка от")}{" "}
                            {item.promo_min_order_amount} RSD
                          </div>
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </InfoBox>
              )}
            </React.Fragment>
          ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(MapPolygon);
